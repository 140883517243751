import {useState, useEffect} from 'react'



import styles from '../styles/AlertMsg.module.css'



export default function AlertMsg({state, title, body}) {

  const [isActive, setIsActive] = useState(null)


  useEffect(() => setIsActive(state), [state])

  return (
    <div className={isActive ? `${styles.body} ${styles.bodyActive}` : styles.body}>

        <h5>{title}</h5>
        <p>{body}</p>

    </div>
  )
}
