import {useState} from 'react'

import styles from '../styles/SliderPrice.module.css'

export default function SliderPrice({priceFilterState, max}) {
    
    const {priceFilter, setPriceFilter} = priceFilterState
    
    const [priceMin, setPriceMin] = useState(priceFilter.min)
    const [priceMax, setPriceMax] = useState(priceFilter.max)
    

    
    const progressStyle = {
        left: `${parseInt((100 * priceFilter.min) / max, 10)}%`,
        right: `${parseInt(100 - (100 * priceMax) / max, 10)}%`
    }
    
    



  return (
    <div className={styles.priceCont}>
        <h4>Cena</h4>

        <div className={styles.slider}>
            <div className={styles.progress} style={progressStyle} ></div>

            <input type="range" min="0" max={max} 
                value={priceMin} 
                onMouseUp={(e) => setPriceFilter({...priceFilter, min: e.target.value})}
                onTouchEnd={(e) => setPriceFilter({...priceFilter, min: e.target.value})}
                onChange={(e) => setPriceMin(e.target.value)}
            />

            <input type="range" min="0" max={max}
                value={priceMax}
                onMouseUp={(e) => setPriceFilter({...priceFilter, max: e.target.value})}
                onTouchEnd={(e) => setPriceFilter({...priceFilter, max: e.target.value})}
                onChange={(e) => setPriceMax(e.target.value)} className={styles.priceMax}
            />
        </div>

        <div className={styles.priceInput}>
            <p>{priceMin} RSD - {priceMax} RSD</p>
        </div>
    </div>
  )
}
