import React from 'react'

import styles from '../styles/SizesTable.module.css'



export default function SizesTable({data}) {
    

  return (
    <div className={styles.body}>
      <table className={styles.table}>
        <thead>
            <tr className={styles.headRow}>
                <th>VELIČINA</th>
                <th>DUŽINA</th>
                <th>ŠIRINA</th>
            </tr>
          </thead>

          <tbody>
          {
              data['size'].map((name, i) => <tr className={styles.dataRow} key={i}>
                  <td>{data['size'][i]}</td>
                  <td>{data['length'][i]}</td>
                  <td>{data['width'][i]}</td>
              </tr>)
          }
          </tbody>
      </table>
    </div>
  )
}
