import {useEffect, useState} from 'react'
import {useNavigate, Routes, Route, Link} from 'react-router-dom'

import Axios from 'axios'


import styles from './AdminScreen.module.css'

import AddProduct from './components/AddProduct'
import StatusPrice from './components/StatusPrice'
import Additional from './components/Additional'






export default function AdminScreen({categories, anime}) {

    const navigate = useNavigate()

    const token = localStorage.getItem('token')



    const EditScreen = () => {

        const [editSlug, setEditSlug] = useState(false)

        const submitHandler = (e) => {
            e.preventDefault()

            const formData = new FormData(e.target)
            setEditSlug(formData.get('editSlug'))
        }

        const deleteHandler = async (e) => {
            e.preventDefault()

            const formData = new FormData(e.target)
            const delSlug = formData.get('delSlug')

            if(!delSlug) { alert("Molimo unesite validan link"); return; }

            try {
                await Axios.post('/api/admin/delete-product', {slug: delSlug}, {headers: {Authorization: `Bearer ${token}`}})
                alert("USPESNO IZBRISAN PROIZVOD")
            } catch (error) {
                alert(error.message)

                if(error.response.status === 403) { localStorage.removeItem("token"); navigate('/login') }
            }
        }



        return (
        <div>
            <form onSubmit={deleteHandler}>
                <h6>Obrisite Proizvod</h6>
                <input type="text" name="delSlug" />

                <input type="submit" value="Obrisi" />
            </form>
            <hr/>

            <h4>Izmenite Proizvod</h4>
            <form onSubmit={submitHandler}>
                <h6>Unesite Link:</h6>
                <input type="text" name="editSlug" />

                <input type="submit" value="Potvrdi" />
            </form>

            {editSlug && <AddProduct token={token} categories={categories} anime={anime} navigate={navigate} edit={true} slug={editSlug} />}
        </div>
        )
    }





    useEffect(() => {
        if(!token) {
            navigate('/login');
            return;
        }
    }, [])
    

  return (
    <div className={styles.body}>

    <aside className={styles.aside}>
        <Link to="/admin">Dodaj Proizvod</Link>
        <Link to="/admin/discount">Status i Cena</Link>
        <Link to="/admin/additional">Kategorije</Link>
        <Link to="/admin/edit">Izmeni status prozivoda</Link>
    </aside>


    <div className={styles.main}>
        <Routes>
            <Route path="/" element={<AddProduct token={token} categories={categories} anime={anime} navigate={navigate} />} />
            <Route path="/discount" element={<StatusPrice token={token} categories={categories} navigate={navigate} />} />
            <Route path="/additional" element={<Additional token={token} navigate={navigate} />} />
            <Route path="/edit" element={<EditScreen navigate={navigate} />} />

            {/* <Route path="/edit/:slug" element={<AddProduct token={token} categories={categories} anime={anime} navigate={navigate} edit={true} />} /> */}
        </Routes>
    </div>





    </div>
  )
}
