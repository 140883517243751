import {useState} from 'react'

import Axios from 'axios'


import styles from './StatusPrice.module.css'



export default function StatusPrice({token, categories, navigate}) {

    const [priceOff, setPriceOff] = useState('')
    const [targetCategories, setTargetCategories] = useState({...categories})
    const catKeys = Object.keys(categories)


    const handleDiscount = async () => {
        let categoryArr = catKeys.filter(x => targetCategories[x])
        if(categoryArr.length === 0) { categoryArr = catKeys }

        let categoryList = ""
        categoryArr.forEach(el => categoryList += `${el}|`)
        

        let discount = Number(priceOff) ? Number(priceOff) : priceOff
        if(!discount) { alert("Morate da unesete snizenje"); return; }

        try {
            await Axios.post('/api/admin/discount', {priceOff: discount, categoryList})
            alert("Uspesno ste ažurirali cene")
        } catch (error) {
            alert("Nije uspelo")

            if(error.response.status === 403) { localStorage.removeItem("token"); navigate('/login') }
        }
    }

    
  return (
    <div className={styles.body}>
        <div className={styles.discount}>
            <input type='text' value={priceOff} onChange={(e) => setPriceOff(e.target.value)} />

            <div className={styles.dropdownCont}>
                <h6>Izaberi Kategorije <span id={styles.arrowSpan}></span></h6>
                <ul className={styles.dropdown}>
                    {
                    Object.keys(categories).map((el, i) => 
                        <li key={i}>
                            <input
                                type="checkbox"
                                checked={targetCategories[el]}
                                id={el + i}
                                onChange={() => {
                                    setTargetCategories(prev => ({...prev, [el]: !targetCategories[el]}))
                                }}
                            />

                            <label htmlFor={el + i}>{el}</label>
                        </li>
                        )
                    }
                </ul>
            </div>

            <button onClick={handleDiscount}>Potvrdi</button>
        </div>
    </div>
  )
}
