import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Axios from 'axios'

import SlideShow from '../components/SlideShow'
import ProductsCatalog from '../components/ProductsCatalog'


import styles from '../styles/HomeScreen.module.css'


export default function HomeScreen() {

    const navigate = useNavigate()


    const [newProducts, setNewProducts] = useState(null)








    useEffect(() => {
        const getNewProducts = async () => {
            try {
                const {data} = await Axios.get('/api/public/new')
                if(data) { setNewProducts(data) }
            } catch (error) {
                console.log(error.message)
                return;
            }
        }

        getNewProducts()
    }, [])

  return (
    <div className={styles.body}>

        <SlideShow />

        <div className={styles.main}>
            {
            newProducts &&
                <div className={styles.newProductsCont}>
                    <h3>Nova Izdanja</h3>

                    <ProductsCatalog props={newProducts} />

                    <button onClick={() => navigate('/shop')}><h6>Pogledaj sve proizvode</h6></button>
                </div>
            }

            <div className={styles.animeCont}>
                <Link to="/shop?anime=Naruto"><img src="naruto.png" alt="naruto anime" /></Link>
                <Link to="/shop?anime=Hunter X Hunter"><img src="hunterXhunter.png" alt="hunter x hunter anime" /></Link>
                <Link to="/shop?anime=Bleach"><img src="bleach.png" alt="bleach anime" /></Link>
                <Link to="/shop?anime=Jojo's Bizzare Adventures"><img src="jojo.png" alt="jojo's bizzare adventures anime" /></Link>
                <Link to="/shop?anime=Dragon Ball"><img src="dragonBallZ.png" alt="dragon ball z" /></Link>
                <Link to="/shop?anime=Chainsaw Man"><img src="chainsawMan.png" alt="chainsaw man anime" /></Link>
            </div>
        </div>

            <div className={styles.categoriesCont}>
                <Link to="/shop?category=zimska kapa" className={styles.kape}></Link>
                <Link to="/shop?category=podloga za mis" className={styles.podloge}></Link>
                <Link to="/shop?category=jastuk" className={styles.jastuci}></Link>
                <Link to="/shop?category=solja" className={styles.solje}></Link>
                <Link to="/shop?category=akrilni stand" className={styles.standovi}></Link>
                <Link to="/shop?category=bedz" className={styles.bedzevi}></Link>
            </div>
    </div>
  )
}
