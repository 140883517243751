import {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'

import styles from '../styles/SlideShow.module.css'

export default function SlideShow() {

    const navigate = useNavigate()
    
    const [slideCounter, setSlideCounter] = useState(1)
    const [slideTrans, setSlideTrans] = useState('1s')
    const [buttonOff, setButtonOff] = useState(false)
    const slideLength = document.querySelectorAll(`.${styles.slideCont} img`).length


    const slideShowArr = [
        {
            src: '/mysteryBox-slide.png',
            mobSrc: '/mysteryBox-slide-phone.png',
            alt: 'Mystery Box',
            text: 'Iznenadi se',
            destination: 'mystery box',
            top: '85%',
            left: '45%'
        },
        {
            src: '/home-bg.png',
            mobSrc: '/home-bg-phone.png',
            alt: 'Home Background',
            text: 'Prodavnica',
            destination: '',
            top: '75%',
            left: '25%'
        },
        {
            src: '/nakit-slide.png',
            mobSrc: '/nakit-slide-phone.png',
            alt: 'Nakit',
            text: 'Nakit',
            destination: 'nakit',
            top: '85%',
            left: '49%'
        },
        {
            src: '/majice-slide.png',
            mobSrc: '/majice-slide-phone.png',
            alt: 'Majice',
            text: 'Majice',
            destination: 'majica',
            top: '67.5%',
            left: '46%'
        },
        {
            src: '/duksevi-slide.png',
            mobSrc: '/duksevi-slide-phone.png',
            alt: 'Dukserice',
            text: 'Dukserice',
            destination: 'dukserica',
            top: '85%',
            left: '46%'
        },
        {
            src: '/mysteryBox-slide.png',
            mobSrc: '/mysteryBox-slide-phone.png',
            alt: 'Mystery Box',
            text: 'Iznenadi se',
            destination: 'mystery box',
            top: '85%',
            left: '45%'
        },
        {
            src: '/home-bg.png',
            mobSrc: '/home-bg-phone.png',
            alt: 'Home Background',
            text: 'Prodavnica',
            destination: '',
            top: '75%',
            left: '25%'
        }
    ]

    const slideJsx = (src, alt, text, destination, top, left, key) => (
        <div className={styles.imgCont} key={key}>
            <img  src={src} alt={alt} />
            <button 
                onClick={() => destination ? navigate(`/shop?category=${destination}`) : navigate('/shop')}
                style={{top: `${top}`, left: `${left}`}}
            ><h3>{text}</h3></button>
        </div>
    )

    const slideHandler = (add) => {
        setSlideTrans('1s')
        setButtonOff(true)

        let num = 1
        if(!add) { num = -1 }

        let tmp = slideCounter + num
        setSlideCounter(tmp)
        setGlobalTimer(prev => !prev)
    }

    const transitionHandler = () => {
        if(slideCounter === slideLength - 1) { setSlideCounter(1); setSlideTrans('none') }
        if(slideCounter === 0) { setSlideCounter(slideLength - 2); setSlideTrans('none') }

        setButtonOff(false)
    }

    const getSlideStyle = () => {return {transform: `translateX(-${slideCounter * 100}%)`, transition: slideTrans}}



    const clickEl = document.querySelector(`#${styles.slideNext}`)
    const [globalTimer, setGlobalTimer] = useState(true)
    const interval = useRef(null)

    useEffect(() => {
        interval.current = setInterval(() => clickEl.click(), 5000)

        return () => { clearInterval(interval.current); interval.current = null; }
    }, [clickEl, globalTimer])
    

  return (
    <section className={styles.hero}>
        <div className={styles.slideCont} onTransitionEnd={() => transitionHandler()} style={getSlideStyle()}>
        {
            window.innerWidth > 768 ? 
                slideShowArr.map((el, i) => slideJsx(el.src, el.alt, el.text, el.destination, el.top, el.left, i)) :
                slideShowArr.map((el, i) => slideJsx(el.mobSrc, el.alt, el.text, el.destination, "90%", "50%", i))
        }
        </div>

        <button id={styles.slidePrev} disabled={buttonOff} onClick={() => slideHandler(false)}><span></span></button>
        <button id={styles.slideNext} disabled={buttonOff} onClick={() => slideHandler(true)}><span></span></button>

        <ul className={styles.slideDotsCont}>
            {
                slideShowArr.map((el, i) => {
                    if( i !== 0 && i !== slideShowArr.length - 1 ) {
                        return <li
                            key={i}
                            style={i === slideCounter ? {backgroundColor: 'var(--white-bg)', border: "3px solid var(--white-bg)"} : {backgroundColor: 'rgba(0, 0, 0, 0)', border: "3px solid var(--white-bg)"}}
                        ></li>
                    }
                })
            }
        </ul>
    </section>
  )
}
