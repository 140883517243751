import {useState, useContext, useRef} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'

import styles from '../styles/Header.module.css'
import CartDisplay from './CartDisplay';
import MenuDisplay from './MenuDisplay';
import { Store } from '../Store';

export default function Header(props) {

    const {anime} = props

    const navigate = useNavigate()

    const searchRef = useRef('')
    const [searchOpen, setSearchOpen] = useState(false)
    const submitSearch = () => {
        const {value} = searchRef.current
        if(value) { navigate(`/shop?search=${value}`); navigate(0); }
    }

    const [menuOpen, setMenuOpen] = useState(false)
    const menuState = {menuOpen, setMenuOpen}

    const location = useLocation().pathname.split('/')[1]

    const {state: {cartOpen}, dispatch: ctxDispatch} = useContext(Store)


  return (
    <div className={styles.body}>



        <div className={styles.headerMsg}> <p>Besplatna dostava za porudzbine preko <span style={{whiteSpace: 'no-wrap'}}>10000 rsd</span></p> </div>

        <header>
            <button className={styles.hamburger} onClick={() => setMenuOpen(true)}> <img src="/hamburger.png" alt="Menu" /> </button>

            <Link to="/"> <img className={styles.logo} src="/logo.png" alt="Website Logo" width="128px" height="128px" /> </Link>


            <ul className={styles.linkCont}>
                <li className={location === '' ? styles.linkActive: {}}> <Link to="/"><h4>Home</h4></Link> </li>
                <li className={location === 'shop' ? styles.linkActive : {}}> <Link to="/shop"><h4>Prodavnica</h4></Link> </li>
                <li id={styles.liAnime}>
                    <h4>Anime</h4>

                    <ul className={styles.animeList}>
                        { Object.keys(anime).map((el, i) => <Link to={`/shop?anime=${el}`} key={i}>{el}</Link>) }
                    </ul>
                </li>
                <li className={location === 'dizajn-po-zelji' ? styles.linkActive : {}}> <Link to="/dizajniraj"><h4>Dizajniraj</h4></Link> </li>
            </ul>


            <div className={styles.logoCont}>
                <input
                    id="startSearch"
                    type="text"
                    className={searchOpen ? `${styles.searchQ} ${styles.searchQActive}` : styles.searchQ}
                    ref={searchRef}
                    placeholder="Pretrazi..."
                    onKeyDown={(e) => { if (e.key === "Enter") { submitSearch() } }}
                />
                <img onClick={() => setSearchOpen(prev => !prev)} className={styles.icon} src="/search.png" alt="Search Button" />
                <img className={styles.cartIcon} src="/cart.png" alt="Cart Button" onClick={() => ctxDispatch({type: "OPEN_CART"})} />
            </div>
        </header>

        <ul className={styles.categoryNav}>
            <li> <Link to="/shop?category=dukserica">Duksevi</Link> </li>
            <li> <Link to="/shop?category=majica">Majice</Link> </li>
            <li> <Link to="/shop?category=zimska kapa">Kape</Link> </li>
            <li> <Link to="/shop?category=podloga za mis">Podloge za miš</Link> </li>
            <li> <Link to="/shop?category=jastuk">Jastuci</Link> </li>
            <li> <Link to="/shop?category=solja">Šolje</Link> </li>
            <li> <Link to="/shop?category=bedz">Bedževi</Link> </li>
        </ul>

        <MenuDisplay menuState={menuState} anime={anime} />
        <CartDisplay cartState={{cartOpen, ctxDispatch}} />
    </div>
  )
}
