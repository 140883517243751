import React from 'react'

export default function PolitikaPrivatnosti() {
  return (
    <pre className="terms-cont">
      Politika privatnosti

Datum primene: 01. jun .2021

DISKLEJMER

Animeprodaja („nama“, „mi“ ili „naš“) rukovodi https://animeprodaja.com („Usluga“).

Ova stranica Vam objašnjava našu politiku koja se tiče skupljanja, korišćenja i obelodanjivanja ličnih podataka kada koristite našu Uslugu i izbora koje ste povezali sa tim podacima.

Mi koristimo Vaše podatke kako bismo omogućili i unapredili Uslugu. Korišćenjem Usluge pristajete na skupljanje i korišćenje informacija u skladu sa ovom politikom. Osim ako u ovoj Politici privatnosti nije drugačije navedeno, uslovi korišćenja navedeni u ovoj Politici privatnosti imaju ista značenja kao ona u našim Uslovima korišćenja kojima se može pristupiti putem linka: https://animeprodaja.com/uslovi-koriscenja/

PROVAJDERI USLUGA

Možemo uposliti treća lica i kompanije („Provajdere Usluga“) koje će pružiti usluge u naše ime, poboljšati našu uslugu, i vršiti usluge vezane za Uslugu ili nam pomoći u analiziranju toga kako se naše usluge koriste.

Ova treća lica imaju pristup Vašim Ličnim Podacima jedino da bi izvršili ove zadatke za nas i u obavezi su da ih ne obelodanjuju ili koriste u bilo koje druge svrhe.

Analitika

Možemo koristiti treća lica – Provajdere Usluga- kako bismo nadgledali i analizirali korišćenje naših Usluga.

Google Analytics Google Analytics je Google-ov analitički internet servis koji prati i izveštava o internet saobraćaju. Google koristi ove podatke da prati i nadgleda korišćenje naših Usluga. Ovi podaci se dele sa drugim Google servisima. Google može da iskoristi prikupljene podatke da kontekstualizuje i personalizuje oglase na sopstvenoj reklamnoj mreži. Možete da izaberete da Vaša aktivnost u Uslugama ne bude dostupna Google Analytics-u tako što ćete instalirati Google Analytics opt-out dodatak za pretraživač. Ovaj dodatak sprečava Google Analytics JavaScript (ga.js, analytics.js, i dc.js) da deli informacije o posetama sa Google Analytics-om. Za više informacija o privatnosti na Google-u posetite njihovu stranicu Privatnost i Uslovi korišćenja: https://policies.google.com/privacy

Bihevioralni remarketing

Animeprodaja koristi usluge remarketinga kako bi se reklamirao na sajtovima trećih lica nakon što posetite našu Uslugu. Mi i naši prodavci, treca lica, koristimo kolačiće (cookies) radi informisanja, optimizacije i pružanja oglasa na osnovu vaših prethodih poseta našim Uslugama.

Google AdWords Google AdWords remarketing servis je omogućio Google Inc. Možete da isključite Google Analytics za Prikazivanje Oglasa i prilagodite oglase na Google Display Network-u tako što ćete posetiti stranicu za podešavanja na Google Ads-u: https://adssettings.google.com/

Google takođe preporučuje da instalirate Google Analytics Opt-out dodatak za pretraživač – https://tools.google.com/dlpage/gaoptout – na Vašem pretraživaču. Google Analytics Opt-out dodatak za pretraživač omogućava posetiocima da spreče skupljanje i korišćenje njihovih podataka od strane Google Analytics-a. Za više informacija o privatnosti na Google-u posetite njihovu stranicu Privatnost i Uslovi korišćenja: https://policies.google.com/privacy

Facebook Facebook remarketing servis je omogućio Facebook Inc. Da saznate više o Facebook-ovim oglasima baziranim na interesovanju posetite njihovu internet stranicu: https://www.facebook.com/help/164968693837950

Facebook se drži samo regulatornih Principa za Onlajn Bihevioralno Oglašavanje (Self-Regulatory Principles for Online Behavioral Advertising) ustanovljenih od strane Saveza za Digitalno Oglašavanje (Digital Advertising Alliance). Možete da se isključite sa Facebook-a i drugih učestvujućih kompanija preko Saveza za Digitalno Reklamiranje u SAD-u: http://optout.aboutads.info, preko Saveza za Digitalno Oglašavanje Kanade u Kanadi: https://youradchoices.ca/ ili preko Evropskog Saveza za Interaktivno Digitalno Oglašavanje: http://www.youronlinechoices.eu/, a možete se isključiti i preko podešavanja na Vašoj mobilnoj napravi. Za više informacija o Facebook-ovoj politici privatnosti posetite Facebook-ovu Politiku Podataka: https://www.facebook.com/privacy/explanation

MailChimp MailChimp imejl remarketing servis je omogućio The Rocket Science Group LLC. Za više informacija o politici privatnosti The Rocket Science Group LLC-a posetite njihovu stranicu: https://mailchimp.com/legal/privacy/

Uplate

U sklopu Usluga mi pružamo plaćene proizvode i/ili usluge. U tom slučaju mi koristimo usluge trećih lica radi obrade uplata (na pr. obrađivači uplata).

Nećemo skladištiti niti skupljati detalje sa vaše platne kartice. Te informacije idu direktno našim obrađivačima uplata, trećim licima čija je obrada vaših ličnih informacija ustanovljena u njihovoj Politici Privatnosti. Ovi obrađivači uplata se drže standarda koje je postavio PCI-DSS po nalogu PCI Saveta o Sigurnosnim Standardima, koji predstavlja združeni napor brendova kao što su Visa, Master card, American Express i Discover. Uslovi PCI-DSS-a pomažu u garantovanju sigurnosti u rukovođenju informacijama o uplatama.


DEFINICIJE

Lični Podaci – Lični Podaci su podaci o živoj osobi koja se može identifikovati preko tih podataka (ili preko njih i drugih informacija koje ili imamo ili ćemo najverovatnije tek imati).

Podaci o Korišćenju – Podaci o Korišćenju su podaci koji se automatski skupljaju bilo putem korišćenja Usluge ili putem same Uslužne infrastrukture (na pr. vreme provedeno na posećenoj stranici).

Kolačići – Kolačići su malobrojni podaci koji se čuvaju na računaru (napravi) Korisnika.

Kontrolor Podataka – Kontrolor Podataka je osoba koja (bilo sama ili u saradnji ili zajedno sa drugim osobama) procenjuje svrhu i način na koji su ili će biti obrađeni bilo kakvi lični podaci. U svrhu ove Politike Privatnosti, mi smo Kontrolori Podataka za Vaše podatke.

Obrađivač Podataka (ili Provajder Usluga) – Obrađivač Podataka (ili Provajder Usluga) je bilo koja osoba (osim zaposlenog kod Kontrolora Podataka) koja obrađuje podatke u ime Kontrolora Podataka. Mi možemo koristiti usluge različitih Provajdera Usluga kako bismo efikasnije obradili vaše podatke.

Subjekat Podataka – Subjekat Podataka je bilo koja živa osoba koja je subjekat Ličnih Podataka.

Korisnik – Korisnik je pojedinac koji koristi našu Uslugu. Korisnik se odnosi na Subjekat Podataka, koji je objekat Ličnih Podataka.

OTKRIVANJE (OBELODANJIVANJE) PODATAKA

Poslovne transakcije

Ako Animeprodaja uđe u spajanje, stečaj ili prodaje imovinu, može doći do transfera vaših Ličnih Podataka. Obavestićemo Vas o tome pre nego što se vaši Lični Podaci premeste i postanu predmet drugačije Politike Privatnosti.

Obelodanjivanje snagama zakona

Pod određenim okolnostima Animeprodaja može biti u obavezi da otkrije Vaše Lične Podatke ako nam to traži zakon ili kao odgovor validnom zahtevu državnih vlasti (na primer sud ili državna agencija).

Zakonske obaveze

Animeprodaja može otkriti vaše Lične Podatke kada smo uvereni da je to neophodno kada:

Treba i spoštovati zakonsku obavezu

Treba zaštititi i braniti prava ili imovinu Animeprodaje

Treba sprečiti ili istražiti nezakonite radnje u vezi sa Uslugom

Treba zaštititi ličnu bezbednost korisnika Usluge ili javnosti

Treba zaštititi sajt od pravne odgovornosti

SIGURNOST PODATAKA

Važna nam je sigurnost Vaših podataka, ali Vas molimo da obratite pažnju na to da nijedan vid prenosa preko Interneta ili vid elektronskog skladištenja nije 100% bezbedan. Iako se trudimo da koristimo sva tržišno prihvatljiva sredstva kako bismo zaštitili vaše Lične Podatke, ne možemo garantovati njihovu apsolutnu sigurnost.

‘DO NOT TRACK’ (‘NE PRATI’) SIGNALI

Naš sajt ne podržava „Ne Prati“ („DNT“). Ne Prati je opcija koju Vi podešavate u svom pretraživaču kako biste obavestili sajtove da ne želite da budete praćeni.

Možete uključiti ili isključiti Ne Prati na stranici Karakteristike ili Podešavanja u Vašem pretraživaču.

VAŠA PRAVA

Animeprodaja se trudi da Vam u razumnom okviru dozvoli da ispravljate, menjate, brišete ili ograničite upotrebu Vaših ličnih podataka.

Kad god je to omogućeno, Vi možete da neposredno ažurirate svoje Lične Podatke na odeljku za podešavanje Vašeg naloga. Ako ne budete mogli da promenite svoje Lične Podatke, molimo Vas da nas kontaktirate kako bismo napravili neophodne izmene.

Ako želite da Vas obavestimo koji Lični Podaci o Vama se čuvaju kod nas i ako želite da ih uklonite iz sistema, molimo Vas da nas kontaktirate.

U određenim okolnostima imate pravo:

Da pristupite i primite kopiju Ličnih Podataka koje imamo o Vama

Da ispravite bilo kakve Lične Podatke o Vama koji nisu tačni

Da zahtevate brisanje Ličnih Podataka o Vama

Imate pravo na prenosivost podataka za informacije koje dajete Animeprodaji. Možete da zahtevate da primite kopiju Vaših Ličnih Podataka u uobičajenom elektronskom formatu kako bi njome mogli da upravljate i da je premestite.

Molimo Vas da obratite pažnju na to da ćemo Vam možda tražiti da potvrdite svoj identitet pre nego što odgovorimo na takve zahteve.

LINKOVI KA DRUGIM SAJTOVIMA

Naša Usluga može da sadrži linkove ka drugim sajtovima kojima ne rukovodimo mi. Ako kliknete na link trećeg lica, bićete upućeni na sajt trećeg lica. Savetujemo Vas da uvek pročitate Politiku Privatnosti svakog sajta koji posećujete.

Nemamo kontrolu i ne preuzimamo odgovornost za sadržaj, politike privatnosti ili delanja bilo kojih sajtova i usluga trećih lica.

PRIVATNOST DECE

Naša usluga se ne odnosi na one koji imaju ispod 13 godina („Deca“).

Ne prikupljamo sa namerom informacije preko kojih se mogu identifikovati pojedninci mlađi od 13 godina. Ako ste roditelj ili staratelj i saznate da nam je Vaše Dete poslalo lične podatke, molimo Vas da nas kontaktirate. Ako saznamo da smo prikupili Lične Podatke dece bez saglasnosti roditelja, preduzećemo sve neophodne korake kako bismo uklonili te informacije sa naših servera.

PROMENE OVE POLITIKE PRIVATNOSTI

Povremeno možemo da ažuriramo ovu Politiku Privatnosti. Obavestićemo Vas o bilo kakvim promenama postavljanjem nove Politike Privatnosti na ovu stranicu.

Obavestićemo Vas putem imejla (ako ste se pretplatili na naša obaveštenja) i/ili putem naglašene objave na našoj Usluzi, pre nego što promena bude primenjena i ažuriraćemo „datum primene“ na vrhu ove Politike Privatnosti.

Savetujemo Vas da povremeno pročitate ovu Politiku Privatnosti kako bi proverili da li je došlo do nekih promena. Promene u ovoj Politici Privatnosti su na snazi kada su postavljene na ovu stranicu
    </pre>
  )
}
