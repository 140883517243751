import {useState} from 'react'

import Axios from 'axios'

import styles from './Additional.module.css'


export default function Additional({token, navigate}) {

    const [descArr, setDescArr] = useState([''])


    const submitHandler = async (e) => {
        e.preventDefault()

        const data = new FormData(e.target)
        const entries = Object.fromEntries(data)

        let descBody = []
        Object.keys(entries).forEach(item => item.startsWith("descBody_") ? descBody.push(entries[item]) : null)

        const descData = {head: entries['descHead'], body: descBody}


        const sizeData = {
            size: entries.size.split(';'),
            length: entries.length.split(';'),
            width: entries.width.split(';'),
        }

        try {
            await Axios.post('/api/admin/new-productsInfo', {category: entries.category, descData, sizeData}, {headers: {Authorization: `Bearer ${token}`}})
            
            alert("Uspesno ubacene dodatne informacije")
        } catch (error) {
            alert(error.message)

            if(error.response.status === 403) { localStorage.removeItem("token"); navigate('/login') }
        }
    }



  return (
    <div className={styles.body}>
        <form onSubmit={submitHandler}>
            <h5>
                <label htmlFor="category">Kategorija</label>
                <input type="text" name="category" />
            </h5>

            <h5>
                <label htmlFor="descHead">Deskripcija titl</label>
                <input type="text" name="descHead" />
            </h5>

            <h5>
                <label>Deskripcija telo</label>
                {
                    descArr.map((el, i) =>
                        <input
                            name={`descBody_${i}`}
                            key={i}
                        />
                    )
                }
                <div className={styles.descBtnCont}>
                    <button onClick={(e) => { e.preventDefault(); if(descArr.length > 1) { setDescArr(prev => prev.slice(0, prev.length - 1))  } }}>Ukloni</button>
                    <button onClick={(e) => { e.preventDefault(); setDescArr(prev => ([...prev, ''])) }}>Dodaj</button>
                </div>
            </h5>


            <h5>
                <label htmlFor="size">Velicina</label>
                <input type="text" name="size" />
            </h5>
            <h5>
                <label htmlFor="length">Duzina</label>
                <input type="text" name="length" />
            </h5>
            <h5>
                <label htmlFor="width">Sirina</label>
                <input type="text" name="width" />
            </h5>




            <input type="submit" value="Dodaj"/>
        </form>
    </div>
  )
}
