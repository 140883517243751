import {useState, useContext, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import { Store } from '../Store'

import Axios from "axios"

import styles from '../styles/OrderScreen.module.css'

export default function OrderScreen() {

    const {state: ctxState, dispatch: ctxDispatch} = useContext(Store)
    const {cartItems, customerInfo} = ctxState
    
    const {name, surName, phoneNum, mail, address, city} = customerInfo


    const [cartPrice, setCartPrice] = useState(0)
    const [agree, setAgree] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(true)

    const navigate = useNavigate()




    const submitOrder = async (e) => {
        e.preventDefault()

        const shippingForm = new FormData(e.target)
        const shippingData = Object.fromEntries(shippingForm.entries())

        
        const cartData = [] // WEBSITE DESIGNS
        const customList = [] // CUSTOM DESIGNS
        cartItems.forEach(item => typeof item._id !== 'number' ? cartData.push(item) : customList.push(item))
        // console.log(customList)
        
        const productList = cartData.map(item => { return {id: item._id, qty: item.qty, size: item.size ? item.size : false}; })


        let alertRes = ""
        try {
            const {data} = await Axios.post('/api/public/order', {productList, customList, shippingData})

            alertRes = data
        } catch (error) {
            alertRes = error.response.data
        }
        localStorage.setItem('customerInfo', JSON.stringify(customerInfo))

        setLoadingBtn(false)

        ctxDispatch({type: "ACTIVATE_ALERT", payload: {
            alertOpen: true,
            title: alertRes.title,
            body: alertRes.body
        }})
        setTimeout(() => ctxDispatch({type: "ACTIVATE_ALERT", payload: { alertOpen: false, title: alertRes.title, body: alertRes.body }}), 5000)

        if(!alertRes.body) { navigate('/') }

        return;
    }



    
    useEffect(() => {
        const calcCartPrice = () => {
            let temp = 0
            cartItems.forEach(item => temp += item.qty * (item.newPrice ? item.newPrice :item.price))

            setCartPrice(temp)
        }

        if(cartItems.length === 0) { navigate("/shop") }
        calcCartPrice()
    }, [cartItems, navigate])

  return (
    <div className={styles.body}>
        <Helmet>
            <title>{`Anime Prodaja | Naručivanje`}</title>

            <script>{`document.querySelector("#purchaseButton").addEventListener("click", () => fbq('track', 'Purchase', {num_items: ${cartItems.length}, currency: "RSD", value: ${cartPrice}, contents: [${JSON.stringify([...cartItems])}] }))`}</script>
        </Helmet>

        <h2>Naručivanje</h2>

        <div className={styles.bodyFlex}>
            <form className={styles.form} id="order-form" onSubmit={submitOrder}>
                <div className={styles.nameCont}>
                    <p className={styles.name}>
                        <label htmlFor="name">Ime</label>
                        <input type="text" name="name" defaultValue={customerInfo ? name : false} />
                    </p>

                    <p className={styles.surName}>
                        <label htmlFor="surName">Prezime</label>
                        <input type="text" name="surName" defaultValue={customerInfo ? surName : false} />
                    </p>
                </div>

                <p className={styles.mail}>
                    <label htmlFor="mail">E-Mail adresa</label>
                    <input type="email" name="mail" defaultValue={customerInfo ? mail : false} />
                </p>

                <p className={styles.phone}>
                    <label htmlFor="phoneNum">Broj telefona</label>
                    <input type="number" name="phoneNum" defaultValue={customerInfo ? phoneNum : false} />
                </p>

                <p className={styles.address}>
                    <label htmlFor="address">Adresa</label>
                    <input type="text" name="address" defaultValue={customerInfo ? address : false} />
                </p>

                <p className={styles.city}>
                    <label htmlFor="city">Mesto</label>
                    <input type="text" name="city" defaultValue={customerInfo ? city : false} />
                </p>

                <p className={styles.note}>
                    <label htmlFor="note">Napomena <span>(opcionalno)</span></label>
                    <textarea name="note"></textarea>
                </p>
            </form>

            <div className={styles.main}>
                <h5>Vaša narudžbina</h5>

                <div className={styles.order}>
                    <div className={styles.orderHead}>
                        <h6>Proizvod</h6>
                        <h6>Cena</h6>
                    </div>

                    <div className={styles.orderBody}>
                        {
                        cartItems && cartItems.map((item, i) =>
                            <div className={styles.orderItem} key={i}>
                                <p><b>{item.qty}x</b> {item.name}</p>
                                <h6>{item.newPrice ? item.newPrice : item.price} RSD</h6>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.pricingCont}>
                    <div>
                        <p>Korpa</p>
                        <p>{cartPrice}</p>
                    </div>

                    <div>
                        <p>Poštarina</p>
                        <p>{cartPrice < 10000 ? 450 : 0}</p>
                    </div>

                    <div>
                        <h6>Ukupno</h6>
                        <h6>{cartPrice + (cartPrice < 10000 ? 450 : 0)}</h6>
                    </div>
                </div>

                <div className={styles.ctaCont}>
                    <p>Plaćanje pouzećem <span>?</span></p>
                    <p>
                        Vaši lični podaci koristiće se za obradu vaše narudžbine i u druge svrhe opisane u našoj <Link to="/politika-privatnosti">politika privatnosti</Link>
                    </p>

                    <div className={styles.agreement} onClick={() => setAgree(prev => !prev)}>
                        <input type="checkbox" name="agreement" checked={agree} onChange={() => { return; }} />
                        <label htmlFor="agreement">Pročitao/la sam i prihvatam <Link to="/uslovi-koriscenja">uslovi korišćenja</Link> </label>
                    </div>

                    <button id="purchaseButton" form="order-form" disabled={!agree && !loadingBtn} type="submit"> <h5>Naruči</h5> </button>
                </div>
            </div>
        </div>
    </div>
  )
}
