import React from 'react'

export default function OpstiUslovi() {
  return (
    <pre className="terms-cont">
    Opšti uslovi Online kupovine
        Opšti Uslovi Online Kupovine
        Pre online kupovine, molimo Vas da pročitate opšte uslove prodaje i isporuke kupljenih proizvoda. Ako imate pitanja u vezi sa radom nase online prodavnice, kontaktirajte nas.

        Posetom ili kupovinom na na našem sajtu www.animeprodaja.com prihvatate Uslove korišćenja koji su navedeni u daljem tekstu. Molimo Vas da ih pažljivo pročitate i upoznate se pravilima koja primenjujemo. Animeprodaja zadržava pravo da bez prethodnog obaveštavanja vrši izmene i dopune ovih Uslova korišćenja.

        Izjava o zaštiti privatnosti

        U ime naše internet prodaje obavezujemo se da ćemo čuvati privatnost svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi zaposleni frme Animeprodaja (i poslovni partneri) odgovorni su za poštovanje načela zaštite privatnosti.

        Intelektualna svojina

        Sadržaj koj je prikazan na našem sajtu kao što su stranice, dizajnerski elementi (dugmići, ikonice i sl), tekstovi, fotografije, logotipi… su vlasništvo Animeprodaja ili njegovih dobavljača i ne mogu se koristiti bez predhodne saglasnosti sa našom firmom. Sve informacije dostupne na internet stranama isključivo su informativnog karaktera, ne smeju se koristiti u komercijalne svrhe niti distribuirati trećim licima.

        Vaš nalog i njegova odgovornost

        Ukoliko se registrujete na našem sajtu, odgovorni ste za održavanje i poverljivost svog naloga i lozinki koje ste dobili prilikom registracije na sajtu. Korišćenjem svog naloga odgovorni ste za sve aktivnosti koje se dešavaju na vašem nalogu i sa vašom lozinkom. Maloletnici mogu koristiti usluge naše online prodaje uz nadzor roditelja ili staratelja. Animeprodaja zadržava pravo da odbije pružanja usluga, ukine nalog ili otkaže narudžbenicu ukoliko ustanovi da su prekršeni Uslovi korišćenja. Vaše podatke koje ste naveli prilikom registracije će koristiti radi kupoprodaje proizvoda i obaveštavanja kupaca o novim prozvodima, akcjama, sniženjima…uspostavljanja poslovnog kontakta i neće biti korišćeni u druge svrhe ili ih dostaviti trećim licima.

        Cene proizvoda

        Na našem sajtu su prikazane neto i bruto cene(cene sa PDV-om). Animeprodaja se obavezuje da će prihvatiti svaku cenu po kojoj ste napravili narudžbenicu bez obzira da li se cena proizvoda promenila nakon kreiranja narudžbenice. Animeprodaja ima pravo da otkaže narudžbenicu uz obaveštenje putem telefona ili uz slanje email obaveštenja potrošaču ukoliko je došlo do grube greške prilikom postavljanja cene na sajtu, npr. umesto 10 000 je navedena cena 100 din, i sl.

        Promotivne cene

        Važe do kraja promotivnog perioda ili dok traju zalihe. Promocija traje do isteka zaliha čak iako je datumom promcije drugačije određeno.

        Radno vreme

        Promena (ažuriranje) podataka na sajtu zadržava pravo da može u svako vreme, bez prethodne najave, promeniti podatke prikazane na bilo kojoj stranici sajta. Posetioci Animeprodaja sajta nemaju pravo da jednostrano menjaju uslove korišćenja, a svaka eventualna izmena je nevažeća osim u slučaju da se obe strane saglase pisanim putem.

        Ostalo

        Delimična ili potpuna neprimenjivost ili nezakonitost određene odredbe ovih Uslova korišćenja, neće uticati na punovažnost ostalih odredbi koje ostaju na snazi u celini. Neprimenjiva ili nezakonita odredba će biti zamenjena drugom odgovarajućom odredbom. Saglasni ste da vaša prava i obaveze proistekle iz ovih Uslova korišćenja ne možete preneti na treća lica bez izričitog pismenog odobrenja od Animeprodaje.

        Plaćanje

        Kupovinu naših proizvoda možete ostvariti putem Interneta. Plaćanje možete izvršiti:

        – Uplatom na račun firme (za preduzeća)

        – pouzećem (podrazumeva plaćanje narudžbenice kuriru prilikom preuzimanja pošiljke. Plaćanje se vrši isključivo gotovinom. )

        Dostava putem kurirskih službi

        Dostava naručenih proizvoda iz narudžbenice se obavlja nakon našeg evidentiranja Vaše narudžbence. Dostavu pošiljki vrši kurirska služba i moguća je samo na teritoriji Srbije, kao i Kosova. Za dostavu u BiH i Crnu Goru ide drugačija procedura i možete nas kontaktirati za dodatan info. Troškovi dostave se posebno naplaćuju i zavise od težine pakete i iznose oko 450 rsd. Troškove dostave plaćate zajedno sa proizvodima. Plaćanje narudžbenice vršite kuriru prilikom preuzimanja pošiljke.

        UPOZORENJE!

        Molimo Vas proverite kurira za paket i proverite da nije oštećena ili pakovanja ne utiče primetno . U slučaju oštećenja ambalaže koja sadrži oštećena roba mora da se napiše izveštaj štete. U svakom slučaju molimo ne primajte oštećenu parcelu. Primate oštećenu robu samo na sopstveni rizik.

        OBAVEZNO PROVERITE SASTAV PAKETA PRE PREUZIMANJA ROBE OD KURIRSKE SLUŽBE!

        U nekim slučajevima kada nedostaje neki artikal ili niste dobili odgovarajući proizvod, mi nismo u mogućnosti da odreagujemo posle potpisivanja prilikom pouzeća paketa.Žalbe oštećene robe tokom transporta robe po prijemu potpisanog i prijema od strane kurira neće se uzeti u obzir. U slučaju oštećenja ambalaže koja sadrži oštećena roba mora da napiše izveštaj štete u svakom slučaju. Molimo Vas ne primaju oštećeni parcelu. Recepcija je oštećen robu samo na sopstveni rizik.

        Prilikom povraćaja robe obavezno je vratiti u ispravnom i nekorišćenom stanju i originalno neoštećenom pakavanju. U suprotnom nećemo primiti paket.

        Reklamacije

        U slučaju da imate bilo kakvu reklamaciju na proizvod ili našu uslugu budite slobodni da nas kontaktirate. Budite sigurni da smo spremni i sposobni da rešimo svaku nastalu situaciju. Reklamacijski rok traje 14 dana.

        Povraćaj kupljene robe

        Troškove vraćanja robe i novca snosi kupac, sem u slučajevima kada kupac dobije neispravan ili pogrešan artikal. Troškove slanja robe na servis ili njihove zamene, snosi kupac.

        Pravilnik kurirske službe

        Dostava se vrši isključivo Post ekspres službom ukoliko nisu u pitanju artikli sa promotivnim cenama ili nije drugačije navedeno.
        Rok isporuke je 2-7 radnih dana. Cena dostave zavisi od težine paketa i kreće od 450 rsd. Za pošiljke koje se isporučuju na teritoriji Kosova, isporuka se vrši Post expressom. Za bilo koja pitanja i dodatne informacije – kontaktirajte nas.
    </pre>
  )
}
