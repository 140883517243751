import {useState, useEffect} from 'react'


import Axios from 'axios'


import styles from './AddProduct.module.css'



export default function AddProduct({token, categories, anime, navigate, edit, slug}) {

    const [productData, setProductData] = useState(false)


    const [imgFile, setImgFile] = useState(null)
    const [chosenCategory, setChosenCategory] = useState('')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const [descHead, setDescHead] = useState('')
    const [descArr, setDescArr] = useState([''])


    const submitHandler = async (e) => {
        e.preventDefault()

        const data = new FormData(e.target)
        if(descHead && descArr[0] !== '') {
            let obj = {
                head: descHead,
                body: descArr
            }

            data.append('descData', JSON.stringify(obj))
        }





        if(edit) {
            const test = async () => {
                try {
                    const entries = Object.fromEntries(data.entries())
                    
                    delete entries['image']
                    entries['id'] = productData._id
                    if(descHead && descArr[0] !== '') { entries['descData'] = {head: descHead, body: descArr} }
                    else { delete entries['descData'] }

                    await Axios.post('/api/admin/edit-product', {...entries}, {headers: {Authorization: `Bearer ${token}`}})
                    alert("PROIZVOD JE USPESNO IZMENJEN")
                    e.target.reset()
                    
                    return;
                } catch (error) {
                    alert(error.message)

                    if(error.response.status === 403) { localStorage.removeItem("token"); navigate('/login') }
                    return;
                }
            }

            test()
        }
        if(edit) {return}





        try {
            if (!token) { return; }
            await Axios.post('/api/admin/new-product', data, {headers: {Authorization: `Bearer ${token}`}})
        
            alert("PROIZVOD JE USPESNO DODAN")
            e.target.reset()
        } catch (error) {
            alert(error.response.data)

            if(error.response.status === 403) { localStorage.removeItem("token"); navigate('/login') }
        }
    }


    const getImgStyle = () => { return {backgroundImage: `url('${imgFile}')`, borderRadius: '0', border: "0", backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}; }






    useEffect(() => {
        if(!slug) { return; }

        const getProductInfo = async () => {
            try {
                const {data} = await Axios.get(`/api/public/products/${slug}`)
                
                setProductData(data)
                setChosenCategory(data.category)

                if(data.descData) {
                    const {descData} = data

                    if(descData.head) { setDescHead(descData.head); }
                    if(descData.body) {
                        let tmp = []
                        descData.body.forEach(el => tmp.push(el))
                        setDescArr(tmp)
                    }
                }
            } catch (error) {
                alert("Nesto ne valja")
            }
        }

        getProductInfo()
    }, [])

  return (
    <form onSubmit={submitHandler} className={styles.addForm}>
        <h5 onClick={() => document.querySelector("#image").click()} className={styles.imageWrapper} style={imgFile ? getImgStyle() : {}}>
            <label htmlFor="image "><span id={styles.uploadIcon} style={imgFile ? {display: 'none'} : {}}></span></label>
            <input type="file" name="image" id="image" onChange={(e) => setImgFile(URL.createObjectURL(e.target.files[0]))} />
        </h5>
        <h5>
            <label htmlFor="name" >Ime Proizvoda</label>
            <input type="text" placeholder="Ime proizvoda" name="name" defaultValue={productData ? productData.name : null} />
        </h5>
        <h5 className={styles.dropdownCont} onClick={() => setDropdownOpen(prev => !prev)}>
            <label htmlFor="category" >Kategorija</label>
            <div className={styles.ddInputCont}>
                <input type="text" placeholder="Kategorija" name="category" value={chosenCategory} readOnly />
                <div id={styles.test}></div>
            </div>

            <ul className={styles.dropdown} style={dropdownOpen ? {display: 'block'}: {display: 'none'}}>
                {Object.keys(categories).map((el, i) =>
                    <li key={i} onClick={() => setChosenCategory(el)}>
                        {el}
                    </li>
                )}
            </ul>
        </h5>
        <h5>
            <label htmlFor="anime" >Anime</label>
            <input type="text" placeholder="Anime" name="anime" defaultValue={productData ? productData.anime : null} />
        </h5>
        <h5>
            <label htmlFor="price" >Cena</label>
            <input type="text" placeholder="Cena" name="price" defaultValue={productData ? productData.price : null} />
        </h5>

        <h5>
            <label>Deskripcija Titl</label>
            <input type="text" onChange={(e) => setDescHead(e.target.value)} value={descHead} placeholder="Deskripcija Titl" />
        </h5>
        <h5>
            <div>
                <button onClick={(e) => { e.preventDefault(); setDescArr(prev => [...prev, '']) }}>dodaj</button>
                <button onClick={(e) => { e.preventDefault(); if(descArr.length > 1) { setDescArr(prev => prev.slice(0, prev.length - 1))  } }}>ukloni</button>
            </div>
            
            <label>Deskripcija Telo</label>
            {
            descArr.map((el, i) => 
                <input type="text" onChange={(e) => { e.preventDefault(); let tmp = [...descArr]; tmp[i] = e.target.value; setDescArr(tmp) }} value={descArr[i]} key={i}  />
            )
            }
        </h5>

        <input type="submit" value="Dodaj Proizvod" />
    </form>
  )
}
