import {useEffect, useReducer, useContext, useState} from 'react'
import {useParams} from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Axios from 'axios'

import { Store } from '../Store'
import styles from '../styles/DetailsScreen.module.css'

import SizesTable from '../components/SizesTable'

import Loading from '../components/Loading'



const reducer = (state, action) => {
    switch(action.type) {
        case "FETCH_REQUEST":
            return {...state, loading: true}

        case "FETCH_SUCCESS":
            return {...state, product: action.payload, loading: false}

        case "FETCH_FAIL":
            return {...state, error: action.payload, loading: false}

        default: return state
    }
}


export default function DetailsScreen() {

    const {slug} = useParams()
    
    const [chosenSize, setChosenSize] = useState(null)
    const [qty, setQty] = useState(1)

    const SizeOptions = (sizeData) => // DISPLAY SIZE INPUT
        <div className={styles.optionsCont}>
            <p>Izaberite veličinu</p>

            <div className={styles.inputCont}>
            {
            sizeData['size'].map((el, i) => 
                <button onClick={() => setChosenSize(i)} className={i === chosenSize ? styles.sizeChosen : {}} key={i}>{el}</button>
            )
            }
            </div>
        </div>






    // DEFINE PRODUCT

    const [{product, loading, error}, dispatch] = useReducer(reducer, {
        loading: true,
        product: {},
        error: {}
    })

    let sizeData = false, descData = false
    if(product && product.descData && product.descData.head) {
        descData = product.descData
    }
    else if(Object.keys(product).length !== 0 && product.infoID) {
        const {infoID} = product

        if(infoID.descData) {descData = infoID.descData}
        if(infoID.sizeData.size.length !== 0) {sizeData = infoID.sizeData}
    }


    const {dispatch: ctxDispatch} = useContext(Store)



    const qtyHandler = (e) => {
        if(e.target.value <= 0 && Number(e.target.value)) { return; }
        setQty(e.target.value)
    }

    const AddToCartHandler = (e) => {
        e.preventDefault()

        if(Number(qty) <= 0) { console.log("400"); return; }
        else if(sizeData && (chosenSize < 0 || chosenSize >= product.infoID.sizeData.length)) {
            console.log("404")
            // ADD ERROR MESSAGE
            return;
        }
        
        ctxDispatch({type: "ADD_TO_CART", payload: {...product, qty, size: sizeData ? sizeData.size[chosenSize] : null }})
        ctxDispatch({type: "OPEN_CART"})
    }



    useEffect(() => {
        const getProductData = async () => {
            try {
                dispatch({type: "FETCH_REQUEST"})
                const test = await Axios.get(`/api/public/products/${slug}`)
                const {data} = test

                dispatch({type: "FETCH_SUCCESS", payload: data})
            } catch (error) {
                console.log(error.message)
                dispatch({type: "FETCH_FAIL", payload: error})
            }

            return;
        }

        getProductData()
    }, [])

  return (
    loading ? <Loading /> :
    <div className={styles.body}>
        <Helmet>
            <title>{`Anime Prodaja | ${product && product.name}`}</title>

            <script>{`document.querySelector("#addToCartButton").addEventListener("click", () => fbq('track', 'AddToCart', {currency: 'RSD', value: ${product.price}, content_name: "${product.name}", content_type: "${product.anime} ${product.category}"}))`}</script>
        </Helmet>

        <div className={styles.main}>
            <img src={`../${product.image}`} alt="Product" className={styles.mainImg} />

            <div className={styles.detailsCont}>
                <h3>{product.name}</h3>

                {product.newPrice ? <h4><span style={{textDecoration: 'line-through', fontWeight: 'normal'}}>{product.price} RSD</span> <br/> {product.newPrice} RSD</h4> : <h4>{product.price} RSD</h4>}

                <h6 style={{textTransform: 'uppercase'}}>ANIMEPRODAJA ®️ {product.category}</h6>

                <p>{descData && descData.head}</p>
                <ul>
                {
                    descData.body ? descData.body.map((el, i) => <li key={i}>{el}</li>) : null
                }
                </ul>


                {sizeData && SizeOptions(sizeData)}
                <div className={styles.actionCont}>
                    <input type="number" className={styles.qtyInput} value={qty} onChange={qtyHandler} />
                    <button className={styles.addToCartBtn} id="addToCartButton" onClick={AddToCartHandler} disabled={sizeData && !chosenSize && chosenSize !== 0 ? true : false}>
                        Dodaj u Korpu
                    </button>
                </div>

                {sizeData && <SizesTable data={sizeData} />}
            </div>
        </div>
    </div>
  )
}
