import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import Axios from 'axios'


import styles from './LogInScreen.module.css'

export default function LogInScreen() {

  const navigate = useNavigate()


  const submitHandler = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const entries = Object.fromEntries(formData.entries())

    try {
      const {data} = await Axios.post('/api/admin/login', {...entries})
      
      localStorage.setItem('token', data)
      navigate('/admin')
    } catch (error) {
      alert(error.message)
    }
  }


  useEffect(() => {
    const AlreadyLoggedIn = () => {
      if(localStorage.getItem('token')) {
        navigate('/admin')
      }
    }

    AlreadyLoggedIn()
  }, [navigate])

  return (
    <div className={styles.body}>
      <form onSubmit={submitHandler}>
        <p>
          <label htmlFor="password"></label>
          <input type="text" name="password" />
        </p>

        <input type="submit" value="Uloguj se" />
      </form>
    </div>
  )
}
