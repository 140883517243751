import {useContext, useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {Helmet} from 'react-helmet'


import styles from '../styles/CartDisplay.module.css'
import { Store } from '../Store'


export default function CartDisplay() {

    const navigate = useNavigate()

    const {state: ctxState, dispatch: ctxDispatch} = useContext(Store)
    const {cartItems, cartOpen} = ctxState

    const [total, setTotal] = useState(0)


    const qtyHandler = (payload) => {
        ctxDispatch({type: "CHANGE_QUANTITY", payload})
    }



    useEffect(() => {
        const setTotalPrice = () => {
            if(cartItems.length === 0) { return; }

            let num = 0
            cartItems.forEach(item => {num += ((item.newPrice ? item.newPrice : item.price) * item.qty)})

            setTotal(num)
        }

        setTotalPrice()
    }, [cartItems])



  return (
    <div className={styles.body} style={cartOpen ? {height: '100%', minHeight: '100vw'} : {transitionDelay: '.35s'}} >
        {
        cartItems.length !== 0 &&
        <Helmet>
            <script>{`document.getElementById('orderButton').addEventListener("click", () => fbq('track', 'InitiateCheckout', {num_items: ${cartItems.length}, currency: "RSD", value: ${total}, contents: [${JSON.stringify([...cartItems])}] }))`}</script>
        </Helmet>
        }


        <aside className={styles.xBtn} onClick={() => ctxDispatch({type: "CLOSE_CART"})}></aside>

        <main className={cartOpen ? `${styles.main} ${styles.open}` : styles.main}>
            <button className={styles.closeBtn} onClick={() => ctxDispatch({type: "CLOSE_CART"})} > <img src="/close.png" alt="Close Cart" /> </button>

            {
            cartItems.length === 0 ?
                <h4>Trenutno nema proizvoda u vasoj korpi.</h4> :
                <div className={styles.cartItemsList}>
                    <div className={styles.productsCont}>
                    {
                    cartItems && cartItems.map((item, i) => 
                        <div className={styles.product} key={i}>
                            <img src={item.anime !== "custom" ? `../${item.image}` : item.image} className={item.anime !== "custom" ? '' : styles.customImg} alt="Product in Cart" />

                            <div className={styles.details}>
                                <Link to={`/shop/${item.slug}`}><h5>{item.name}</h5></Link>

                                <div className={styles.detailsBody}>
                                    <span className={styles.info}>
                                        <p>{item.newPrice ? item.newPrice : item.price} RSD</p>
                                        {item.size && <p style={{textTransform: 'uppercase'}}>{item.size}</p>}
                                    </span>

                                    <div className={styles.options}>
                                        <button className={styles.qtySub} onClick={() => qtyHandler({itemID: item._id, add: false, size: item.size ? item.size : false})}> <span id={styles.minus}></span> </button>
                                        <p>{item.qty}</p>
                                        <button className={styles.qtyAdd} onClick={() => qtyHandler({itemID: item._id, add: true, size: item.size ? item.size : false})}> <span id={styles.plus}></span> </button>
                                    </div>
                                </div>
                            </div>

                            <span className={styles.trashBtn} onClick={() => ctxDispatch({type: "REMOVE_FROM_CART", payload: {itemID: item._id, size: item.size ? item.size : false}})}></span>
                        </div>
                    )}
                    </div>

                    <div className={styles.actionCont}>
                        <div className={styles.actionInfo}>
                            <p>Ukupno</p>
                            <h6>{total} RSD</h6>
                        </div>
                        <button onClick={() => { navigate('/order'); ctxDispatch({type: "CLOSE_CART"}); }} id="orderButton"> <h5>Naruci odmah!</h5> </button>
                    </div>
                </div>
            }
        </main>
    </div>
  )
}
