import React from 'react'
import {Link} from 'react-router-dom'

import styles from '../styles/ProductsCatalog.module.css'

export default function ProductsCatalog({props: products, pageActiveState, pageMax}) {

  if(!pageActiveState || !pageMax) {
    return (
      <div className={styles.body}>
        <div className={styles.container}>
            {products && products.map((el, i) =>
                <Link to={`/shop/${el.slug}`} className={styles.product} key={el + i}>
                    <img src={`../${el.image}`} alt="a product" />
  
                    <h5> {el.name} </h5>
  
                    {el.newPrice ? <h6><span style={{textDecoration: 'line-through', fontWeight: 'normal'}}>{el.price} RSD</span> <br/> {el.newPrice} RSD</h6> : <h6>{el.price} RSD</h6>}
                </Link>
            )}
        </div>
      </div>
    )
  }





  const {pageActive, setPageActive} = pageActiveState


  let maxNum = window.innerWidth < 512 ? 8 : 10
  let start = pageActive % maxNum === 0 ? pageActive - maxNum : pageActive - pageActive % maxNum
  let end = start + maxNum
  if(end > pageMax) { end = pageMax }

  const pageArr = () => {
    let tmp = []


    for(let i = start; i < end; i++) {
        tmp[i] = i + 1
    }

    return tmp
}

const clickHandler = (el) => {
  setPageActive(el)
  window.scrollTo(0, 0);
}


  return (
    <div className={styles.body}>
      <div className={styles.container}>
          {products && products.map((el, i) =>
              <Link to={`/shop/${el.slug}`} className={styles.product} key={el + i}>
                  <img src={`../${el.image}`} alt="a product" />

                  <h5> {el.name} </h5>

                  {el.newPrice ? <h6><span style={{textDecoration: 'line-through', fontWeight: 'normal'}}>{el.price} RSD</span> <br/> {el.newPrice} RSD</h6> : <h6>{el.price} RSD</h6>}
              </Link>
          )}
      </div>

      {
      pageActive && pageMax &&
        <div className={styles.pagesCont} style={pageMax === 1 ? {display: 'none'} : {}}>
          {start !== 0 && <button onClick={() => clickHandler(start)}>...</button>}

          {
          pageArr().map((el, i) => 
            <button
              className={pageActive === el ? styles.pageActive : {}}
              onClick={() => clickHandler(el)}
              key={i}
            >{el}</button>)
          }

          {end !== pageMax && <button onClick={() => clickHandler(end + 1)}>...</button>}
        </div>
      }
    </div>
  )
}
