import React from 'react'

export default function UsloviKoriscenja() {
  return (
    <pre className="terms-cont">
      Uslovi korišćenja

PRAVA INTELEKTUALNE SVOJINE

Svi sadržaji na Internet sajtu animeprodaja.com, uključujući, bez ograničenja, sav tekst, grafike, slike, fotografije, kod, multimedijalne fajlove, interaktivne servise i logotipe, su vlasništvo ili su licencirani od strane Animeprodaje, i kao takvi podlezu autorskim pravima i drugim pravima intelektualne svojine prema međunarodnim zakonima i konvencijama.

Sadržaj na Internet sajtu Animeprodaje, je kao takav namenjen Vama i za Vaše lično korišćenje i ne može se koristiti, kopirati, reprodukovati, distribuirati, premeštati, prenositi, prikazivati, prodavati, licencirati, ili se na bilo koji drugin način koristiti u bilo koje svrhe bez prethodnog pismenog odobrenja Animeprodaje, ili na način opisan u ovim uslovima korišćenja.

Korišćenjem ovog internet sajta se slažete da sadržaj sajta nećete koristiti, kopirati ili distribuirati u bilo kakve komercijalne svrhe. Ako Vi preuzmete ili odštampate bilo koji sadržaj za ličnu upotrebu, onda Vi morate na dalje da štitite autorska prava i vlasničke odluke vezane za taj sadržaj. Slažete se da nećete zaobilaziti, onemogućavati ili na druge načine ometati bezbednosna svojstva Internet sajta kao ni svojstva koja su tu da spreče ili ograniče korišćenje ili kopiranje bilo kog sadržaja ili da primene ograničenja na korišćenje Internet sajta animeprodaja.com ili sadržaja na njemu.

CENOVNA POLITIKA

Naši proizvodi se prodaju u ograničenim količinama i dostupnosti. Dali smo sve od sebe da na što verniji način predstavimo svoje proizvode na Internet sajtu animeprodaja.com. Molimo Vas da obratite pažnju na to da može doći do varijacija u stilu, boji, veličini i obliku.

IZUZEĆE OD ODGOVORNOSTI

Korišćenjem Internet sajta animeprodaja.com se obavezujete da oslobađate Animeprodaju odgovornosti, da nećete potraživati i zahtevati od Animeprodaje i da ga nećete smatrati odgovornim za gubitke, štetu i troškove koje ste pretrpeli kao rezultat kršenja ovih uslova korišćenja ili zbog aktivnosti vezanih za Vaš nalog (uključujući nemarno ili nezakonito ponašanje).

PROMENE U CENAMA

Animeprodaja zadržava pravo da u bilo kom trenutku menja cene Animeprodaja proizvoda. Obaveštenje o tome se može dati u bilo kom trenutku postavljanjem izmena na Internet sajtu animeprodaja.com ili na Animeprodaja nalozima na društvenim mrežama.

PLAĆANJE

Sve uplate za dobra na Internet sajtu animeprodaja.com pouzećem, putem privatnih kurirskih službi. Opciju plaćanja birate prilikom kupovine na sajtu Animeprodaje. Nakon što odaberete sve predmete koje biste želeli da kupite, idite na stranicu KORPA i odaberite plaćanje pouzećem. Nakon što završite sa porucivanjem samo se zavalite u stolicu i sačekajte imejl potvrdu da je narudžbina uspela.

PROMENE NA INTERNET SAJTU

Animeprodaja zadržava pravo da u bilo kom trenutku i s vremena na vreme promeni ili zaustavi, privremeno ili za stalno, Internet sajt animeprodaja.com (ili bilo koji njegov deo) sa ili bez prethodnog obaveštenja. Animeprodaja neće biti odgovoran Vama ili bilo kom trećem licu usled bilo kakvih promena, gašenja ili zaustavljanja Internet sajta animeprodaja.com

ANIMEPRODAJA NIJE ODGOVORAN NITI PODLOŽAN GONJENJU USLED BILO KAKVOG GUBITKA ILI ŠTETE KOJA JE PROIZAŠLA IZ SARADNJE SA SAJTOM BILO KOG TREĆEG LICA, TRGOVCEM ILI OPERATEROM SA TOG SAJTA.
    </pre>
  )
}
