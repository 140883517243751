import {useState, useEffect, useReducer, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import { Helmet } from 'react-helmet'
import Axios from 'axios'

import styles from "../styles/ShopScreen.module.css"

import ProductsCatalog from '../components/ProductsCatalog'
import SliderPrice from '../components/SliderPrice'

import Loading from '../components/Loading'



const reducer = (state, action) => {
    switch(action.type) {
        case "FETCH_REQUEST":
            return {...state, loading: true}

        case "FETCH_SUCCESS":
            return {...state, products: action.payload, loading: false}

        case "FETCH_FAIL":
            return {...state, error: action.payload, loading: false}

        default: return state
    }
}



export default function ShopScreen(props) {

    const navigate = useNavigate()

    const firstLoad = useRef(true)

    const historyState = localStorage.getItem('prevState') ? JSON.parse(localStorage.getItem('prevState')) : false
    if(historyState) {
        delete historyState.categories['null']
    }

    const shopScroll = localStorage.getItem('shopScroll') ? JSON.parse(localStorage.getItem('shopScroll')) : 0
    
    

    const [categories, setCategories] = useState(historyState && historyState.categories ? historyState.categories : props.categories)
    const [anime, setAnime] = useState(historyState && historyState.anime ? historyState.anime : props.anime)
    const [animeQ, setAnimeQ] = useState('')


    const [priceFilter, setPriceFilter] = useState({min: 0, max: props.maxPrice})



    const locationObj = useLocation()
    const {search: location} = locationObj // {search, pathname} = locationObj
    const params = new URLSearchParams(location)

    const search = historyState && historyState.search ? historyState.search : params.get('search')



    const productsNum = 24
    const pagesCount = useRef(1)
    const [pageActive, setPageActive] = useState(historyState && historyState.pageActive ? historyState.pageActive : 1)



    const [animeIsOpen, setAnimeIsOpen] = useState(false)
    const [filtersOpen, setFiltersOpen] = useState(false)






    const [{loading, products, error}, dispatch] = useReducer(reducer, {
        loading: false,
        products: [],
        error: "",
    })



    // HANDLE URL PARAMS FILTER INFORMATION
    useEffect(() => {

        const animeParam = params.get('anime')
        if(animeParam) { anime[animeParam] = true }

        const categoryParam = params.get('category')
        if(categoryParam) { categories[categoryParam] = true }

        

        return () => {


            anime[animeParam] = false
            categories[categoryParam] = false
        }
        
    }, [location, anime, categories])
    


    // HANDLE ACTIVE PAGE AND SCROLL RESTORATIKON/POSITION
    useEffect(() => { // SET ACTIVE PAGE BACK TO 1
        const refreshScroll = (y) => {
            window.scrollTo(0, y)
        }


        if(firstLoad.current) {
            firstLoad.current = false;
            refreshScroll(shopScroll)
            return;
        }

        setPageActive(1)
        refreshScroll(0)
    }, [priceFilter, search, categories,  anime, location])



    //HANDLE API PRODUCTS CALL
    useEffect(() => {
        const makeQuery = () => { // MAKE FILTER QUERY FOR BACK END
            const {min, max} = priceFilter
            let query = `price=${min}-${max}&page=${pageActive}&`
            
            let category = ''
            Object.keys(categories).forEach(el => { if(categories[el]) {
                if(category) { category += `|${el}` }
                else {category += el}
            } })

            let animeQ = ''
            Object.keys(anime).forEach(el => { if(anime[el]) {
                if(animeQ) { animeQ += `|${el}` }
                else {animeQ += el}
            } })


            if(search) {query += `name=${search}&`}
            if(category) {query += `category=${category}&`}
            if(animeQ) {query += `anime=${animeQ}&`}

            return query
        }

        const getProducts = async () => { // CALL TO BACK END WITH NEW-MADE QUERY
            try {
                dispatch({type: "FETCH_REQUEST"})
                const {data} = await Axios.get(`/api/public/products?${makeQuery()}`)
                const {res, resCount} = data

                pagesCount.current = Math.ceil(resCount / productsNum)
                dispatch({type: "FETCH_SUCCESS", payload: res})
            } catch (error) {
                dispatch({type: "FETCH_FAIL", payload: error.message})
            }
        }

        getProducts()
        localStorage.removeItem('shopScroll')

        return () => {
            let tmp = {categories,  anime, pageActive}
            localStorage.setItem('prevState', JSON.stringify(tmp))

            let top = (window.pageYOffset || document.body.scrollTop)  - (document.body.clientTop || 0);
            localStorage.setItem('shopScroll', JSON.stringify(top))
        }
    }, [priceFilter, search, categories,  anime, pageActive, location])




  return (
    loading ? <Loading /> :
    error ? <h1>Doslo je do greske</h1> :
    <div className={styles.body}>
        <Helmet>
            <title>Anime Prodaja | Prodavnica</title>

            <meta name="description" content="Najveći katalog anime merch-a: majice, dukserice, nakit, kape, stikeri, posteri, jastuci i još mnogo toga!" />
        </Helmet>
        
        <h2>Prodavnica</h2>

        <button className={styles.filterBtnOpen} onClick={() => setFiltersOpen(true)}>
            <h5>Filteri</h5>
        </button>

        <div className={styles.container}>
            <aside style={filtersOpen ? {left:'0'} : {}}>
                <button className={styles.filterBtnClose} onClick={() => setFiltersOpen(false)}>
                    <img src="/close.png" alt="Close"/>
                </button>

                <SliderPrice priceFilterState={{priceFilter, setPriceFilter}} max={props.maxPrice} />

                <div className={styles.anime}>
                        <div className={styles.dropdownAnime}>
                            <button onClick={() => setAnimeIsOpen(prev => !prev)}>
                                Izaberi Anime
                                <span className={styles.arrow} style={animeIsOpen ? {transform: 'rotate(180deg)'} : {}}></span>
                            </button>

                            <ul style={animeIsOpen ? {display: 'block'} : {}} >
                                <input type="text" value={animeQ} placeholder='Pretrazi' onChange={e => setAnimeQ(e.target.value)} />

                                {anime && Object.keys(anime).map((el, i) =>
                                el !== 'null' &&
                                <li key={el + i}
                                style={animeQ ? el.toLocaleLowerCase().includes(animeQ.toLocaleLowerCase()) ? {} : {display: "none"} : {}}
                                >
                                    <input
                                        type="checkbox" 
                                        checked={anime[el]} 
                                        onChange={(e) => {
                                            e.preventDefault()
                                            setAnime(prev => ({...prev, [el]: !anime[el]}))
                                            if(params.get('anime') && params.get('anime') === el) { navigate('/shop') }
                                        }}
                                        id={el + i}
                                    />
                                    <label htmlFor={el + i}> {el} </label>
                                </li>)}
                            </ul>
                        </div>
                </div>

                <ul className={styles.categories}>
                    {categories && Object.keys(categories).map((el, i) => el !== "null" && <li key={el + i} className={styles.sCategory}>
                        <input
                            type="checkbox"  
                            checked={categories[el]} 
                            onChange={(e) => {
                                e.preventDefault()
                                setCategories(prev => ({...prev, [el]: !categories[el]}))
                            }}
                            id={el + i}
                        />
                        <label htmlFor={el + i}> {el.toLowerCase()} </label>
                    </li>)}
                </ul>
            </aside>

            {products.length !== 0 && <ProductsCatalog props={products} pageActiveState={{pageActive, setPageActive}} pageMax={pagesCount.current} />}
        </div>
    </div>
  )
}


