import React from 'react'
import {Link} from 'react-router-dom'

import styles from '../styles/Footer.module.css'


export default function Footer() {
  return (
    <footer>
      <div className={styles.about}>
        <h4>O nama</h4>
        <p>
          Ovo je počelo iz jedne velike ljubavi prema japanskoj kulturi i animeu. Sada smo zajednica i vodeći brend sa nekoliko različitih 
          koncepata. Naš asortiman ima odličnu kombinaciju interesantnih anime motiva i kvaliteta po pristupačnim cenama, a našim kupcima, 
          ujedno i prijateljima želimo da olakšamo u traženju savršenog anime merch-a
        </p>
      </div>

      <div className={styles.links}>
        <h4>Podrška za kupce</h4>
        <Link to="/opsti-uslovi"><p>Opšti uslovi kupovine</p></Link>
        <Link to="/politika-privatnosti"><p>Politika privatnosti</p></Link>
        <Link to="/uslovi-koriscenja"><p>Uslovi korisćenja</p></Link>
      </div>

      <div className={styles.contact}>
        <h4>Kontakt</h4>
        <a href="mailto: animeprodaja1@gmail.com">animeprodaja1@gmail.com</a>
        <a href="https://instagram.com/animeprodaja" target="_blank" rel="noreferrer"><span id={styles.igIcon}></span></a>
      </div>
    </footer>
  )
}
