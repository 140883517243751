import {useState} from 'react'
import {Link} from 'react-router-dom'


import styles from '../styles/MenuDisplay.module.css'

export default function MenuDisplay({menuState, anime}) {
    const {menuOpen, setMenuOpen} = menuState

    const [animeOpen, setAnimeOpen] = useState(false)


  return (
    <div className={menuOpen ? `${styles.body} ${styles.open}` : styles.body}>
        <button className={styles.closeBtn} onClick={() => setMenuOpen(false)} > <img src="/close.png" alt="Close Cart" /> </button>

        <ul>
            <li onClick={() => setMenuOpen(false)}><Link to="/"><h4>Home</h4></Link></li>
            <li onClick={() => setMenuOpen(false)}><Link to="/shop"><h4>Prodavnica</h4></Link></li>
            <li id={styles.liAnime}>
              <h4 onClick={() => setAnimeOpen(prev => !prev)}>Anime</h4>

              <ul className={animeOpen ? `${styles.animeList} ${styles.animeListOpen}` : styles.animeList}>
                {Object.keys(anime).map((el, i) => <Link to={`/shop?anime=${el}`} key={i}>{el}</Link>)}
              </ul>
            </li>
            <li onClick={() => setMenuOpen(false)}><Link to="/dizajn-po-zelji"><h4>Dizajn Po Zelji</h4></Link></li>
        </ul>
    </div>
  )
}
