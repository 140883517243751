import {useReducer, createContext} from 'react'



const reducer = (state, action) => {
    switch (action.type) {
        
        case "ADD_TO_CART": {
            const newItem = action.payload
            let existItem = false
            if(state.cartItems.length === 0) { return {...state, cartItems: [newItem]} }

            const newItems = state.cartItems.map(item => {
                if(item._id === newItem._id  && item.size === newItem.size) {
                    item.qty = Number(item.qty) + Number(newItem.qty)
                    existItem = true

                    return item;
                } else { return item; }
            })
            if(!existItem) { newItems.push(newItem) }

            localStorage.setItem('cartItems', JSON.stringify(newItems))
            return {...state, cartItems: newItems, cartOpen: true}
        }

        case "CHANGE_QUANTITY": {
            const {itemID, add, size} = action.payload
            let newItems = []

            if(size) {
                newItems = state.cartItems.map(item => {
                    if(item._id === itemID && item.size === size) { item.qty = add ? item.qty + 1 : item.qty > 1 ? item.qty - 1 : 1 }
                    return item
                })
            } else {
                newItems = state.cartItems.map(item => {
                    if(item._id === itemID) { item.qty = add ? item.qty + 1 : item.qty > 1 ? item.qty - 1 : 1 }
                    return item
                })
            }

            localStorage.setItem('cartItems', JSON.stringify(newItems))
            return {...state, cartItems: newItems}
        }

        case "REMOVE_FROM_CART": {
            const {itemID, size} = action.payload
            let newItems = []

            if(size) {
                newItems = state.cartItems.filter(item => (item._id + item.size) !== (itemID + size))
            } else {
                newItems = state.cartItems.filter(item => item._id !== itemID)
            }

            localStorage.setItem('cartItems', JSON.stringify(newItems))
            return {...state, cartItems: newItems}
        }

        case "OPEN_CART": { return {...state, cartOpen: true} }
        case "CLOSE_CART": { return {...state, cartOpen: false} }



        case "OPEN_ALERT": { return {...state, alertOpen: true} }
        case "CLOSE_ALERT": { return {...state, alertOpen: false} }

        case "ACTIVATE_ALERT": {
            return {...state, alertData: {...action.payload}}
        }



        default: return state;
    }
}

export const Store = createContext()

export default function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, {
        cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
        customerInfo: localStorage.getItem('customerInfo') ? JSON.parse(localStorage.getItem('customerInfo')): {},
        cartOpen : false,
        alertOpen: true,
        alertData: {alertOpen: false, title: '', body: ''}
    })

    const val = {state, dispatch}

    return <Store.Provider value={val}> {props.children} </Store.Provider>
}