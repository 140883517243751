import {useState, useEffect, useContext} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Axios from 'axios'

import {Helmet} from 'react-helmet'


import ScrollToTop from './ScrollToTop'
import Header from "./components/Header";

import HomeScreen from './screens/HomeScreen';
import ShopScreen from './screens/ShopScreen';
// import DesignScreen from './screens/DesignScreen'

import DetailsScreen from './screens/DetailsScreen';
import OrderScreen from './screens/OrderScreen';

import AdminScreen from './admin/AdminScreen';
import LogInScreen from './admin/LogInScreen';

import OpstiUslovi from './footerEls/OpstiUslovi';
import UsloviKoriscenja from './footerEls/UsloviKoriscenja';
import PolitikaPrivatnosti from './footerEls/PolitikaPrivatnosti';

import Footer from './components/Footer';


import AlertMsg from './components/AlertMsg';
import { Store } from './Store'



function App() {

  const [categories, setCategories] = useState(null)
  const [anime, setAnime] = useState(null)
  const [maxPrice, setMaxPrice] = useState(10000)



  const {state: ctxState, dispatch: ctxDispatch} = useContext(Store)
  const {alertData} = ctxState
  
  
  
  useEffect(() => {
    const getFilters = async () => {
      const {data} = await Axios.get('/api/public/filters')
      if(!data) { return; }

      let tmp = {}
      const {categories, anime, maxPrice} = data
      
      categories.forEach(el => tmp[el] = false)
      setCategories(tmp)

      tmp = {}
      anime.forEach(el => tmp[el] = false)
      setAnime(tmp)

      setMaxPrice(maxPrice)
    }

    getFilters()
  }, [])

  
  
  return (
    <Router>
      <ScrollToTop />
      <Helmet>
        <title>Anime Prodaja</title>

        <script type="text/javascript">
          {"!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '3802487833370235');fbq('track', 'PageView');"}
        </script>

        
        <meta charSet='UTF-8' />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />

        {/* <meta name="description" content="Najveće tržište Anime proizvoda na balkanu! duksevi, majice, nakit, posteri, jastuci i još mnogo toga!" /> */}
        <meta name="description" content="Anime prodavnica u Srbija sa najvećim izborom predmeta uključujući i anime majice, dukserice, šolje, maske za telefon, nakit, merch, kačkete, postere, bedž.... Najkvalitetnija štampa sa najnovijom tehnologijom koja dugo traje. Ovaj anime shop ima sve što vam treba za najbolji anime poklon za rođendan ili bilo koju priliku." />
        <meta name="keywords" content="anime srbija, anime, naruto, anime stvari, anime duksevi, anime majice, majice, duksevi, najbolji anime merch, anime merch, merch" />

        <meta property="og:image" content="https://animeprodaja.com/home-bg.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
      </Helmet>

      { anime && <Header anime={anime} /> }

      <main>
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          {anime && categories && maxPrice && <Route exact path="/shop" element={<ShopScreen categories={categories} anime={anime} maxPrice={maxPrice} />} />}
          {/* <Route exact path="/dizajniraj" element={<DesignScreen />} /> */}

          <Route path="/shop/:slug" element={<DetailsScreen />} />
          <Route path="/order" element={<OrderScreen />} />

          {anime && categories && <Route path="/admin/*" element={<AdminScreen categories={categories} anime={anime} />} />}
          <Route path="/login" element={<LogInScreen />} />


          <Route path="/opsti-uslovi" element={<OpstiUslovi />} />
          <Route path="/politika-privatnosti" element={<PolitikaPrivatnosti />} />
          <Route path="/uslovi-koriscenja" element={<UsloviKoriscenja />} />
        </Routes>
      </main>

    <AlertMsg state={alertData.alertOpen} title={alertData.title} body={alertData.body}/>

     <Footer />
    </Router>
  );
}

export default App;
